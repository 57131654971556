import { createRouter, createWebHistory, START_LOCATION } from "vue-router";
import store from '../store/index';
// import mixins from '../mixins/mixins'
import axios from 'axios';
import UserService from '../api-services/user.service';
import AppLayout from '../layouts/AppLayoutView';
import PublicLayout from '../layouts/PublicLayoutView';
import CustomLayout from '../layouts/CustomAppLayoutView';
import LandingLayout from '../layouts/LandingLayoutView';
import VueCookies from 'vue-cookies';
// import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
// let analytics = AnalyticsBrowser.load({ writeKey: process.env.VUE_APP_CUSTOMER_IO_API_KEY });

const routes = [

	// public layout
	{
		name: "public",
		path: "/",
		component: PublicLayout,
		children: [
			{
				path: "/login",
				name: "login-view",
				component: () =>
					import("../views/authentication/LoginView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Login'
				}
			},
			{
				path: "/register",
				name: "register-avatar-view",
				component: () =>
					import("../views/authentication/RegisterAvatarView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Register'
				}
			},
			{
				path: "/register-type",
				name: "register-type-view",
				component: () =>
					import("../views/authentication/RegisterTypeView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Register'
				}
			},
			{
				path: "/register-privacy",
				name: "register-terms-privacy-view",
				component: () =>
					import("../views/authentication/RegisterTermsPrivacyView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Register'
				}
			},
			{
				path: "/verify-email",
				name: "register-verify-email",
				component: () =>
					import("../views/authentication/RegisterVerifyEmailView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Verify Email'
				}
			},
			{
				path: "/email-validation/token/:token",
				name: "email-verified-page",
				component: () =>
					import("../views/authentication/EmailVerifiedView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Email Verified'
				}
			},
			{
				path: "/forgot-password",
				name: "forgot-password-view",
				component: () =>
					import("../views/authentication/ForgotPasswordView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Forgot Password'
				}
			},
			{
				path: "/reset-password/token/:token/:expiresAt",
				name: "reset-password",
				component: () =>
					import("../views/authentication/ResetPasswordView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Reset Password'
				}
			},
			{
				path: "/",
				name: "homepage-view",
				component: () =>
					import("../views/public/HomepageView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Home'
				}
			},
			{
				path: "/cookie-settings",
				name: "cookie-settings-view",
				component: () =>
					import("../views/public/CookieSettingsView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Cookies',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
			{
				path: "/terms-service",
				name: "terms-service-view",
				component: () =>
					import("../views/public/TermsOfServiceView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Terms',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
			{
				path: "/privacy-notice",
				name: "privacy-notice-view",
				component: () =>
					import("../views/public/PrivacyNoticeView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Privacy',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
			{
				path: "/faq",
				name: "FAQ-view",
				component: () =>
					import("../views/public/FAQView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Faq',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
			{
				path: "/promotions-terms",
				name: "promotions-view",
				component: () =>
					import("../views/public/PromotionsTermsView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Promotions Terms',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
			{
				path: "/loyalty-rules",
				name: "loyalty-view",
				component: () =>
					import("../views/public/LoyaltyRulesView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					basic: true,
					title: 'Loyalty Rules',
					metaTags: [
						{
							name: 'robots',
							content: 'noindex,nofollow',
						}
					]
				}
			},
		]
	},

	{
		name: "custom",
		path: "/",
		component: CustomLayout,
		children: [
			{
				path: "/daily-bonus",
				name: "daily-bonus-view",
				component: () =>
					import("../views/private/DailyBonusView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: false,
					title: 'Daily Bonus'
				}
			},
		]
	},

	// logged in layout

	{
		name: "app",
		path: "/",
		component: AppLayout,
		children: [
			{
				path: "dashboard",
				name: "dashboard-view",
				component: () =>
					import("../views/private/DashboardView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: true,
					title: 'Dashboard'
				},
			},
			{
				path: "/profile",
				name: "profile-view",
				component: () =>
					import("../views/private/ProfileView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Profile'
				}
			},
			{
				path: "/settings",
				name: "settings-view",
				component: () =>
					import("../views/private/SettingsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Settings'
				}
			},
			{
				path: "/notifications",
				name: "notifications-view",
				component: () =>
					import("../views/private/NotificationsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Notifications'
				}
			},
			{
				path: "/active-time-rewards",
				name: "active-time-rewards-view",
				component: () =>
					import("../views/private/ActiveTimeRewardsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Rewards'
				}
			},
			{
				path: "/daily-tasks",
				name: "daily-tasks-view",
				component: () =>
					import("../views/private/DailyTasksView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Daily Tasks'
				}
			},
			{
				path: "/game/:slug",
				name: "game-view",
				component: () =>
					import("../views/private/GameView.vue"),
					meta: {
						requiresAuth: true,
						needsAppAccess: true,
						hasAccess: true,
						// title: 'Game'
					}
			},
			{
				path: "/sales",
				name: "sales-view",
				component: () =>
					import("../views/private/SalesView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Sales'
				}
			},
			{
				path: "/partners",
				name: "partners-view",
				component: () =>
					import("../views/private/PartnersView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Partners'
				}
			},
			{
				path: "/bundle",
				name: "bundle-view",
				component: () =>
					import("../views/private/BundleView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Bundle'
				}
			},
			{
				path: "/deals",
				name: "deals-view",
				component: () =>
					import("../views/private/DealsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Deals'
				}
			},
			{
				path: "/vault",
				name: "vault-view",
				component: () =>
					import("../views/private/VaultView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Vault'
				}
			},
			{
				path: "/checkout/:sku",
				name: "checkout-view",
				component: () =>
					import("../views/private/CheckoutView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Checkout'
				}
			},
			{
				path: "/events",
				name: "events-list-view",
				component: () =>
					import("../views/private/EventsListView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Events'
				}
			},
			{
				path: "/events/:slug",
				name: "event-view",
				component: () =>
					import("../views/private/EventView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					// title: 'Event Page'
				}
			},
			{
				path: "/thank-you",
				name: "thank-you-view",
				component: () =>
					import("../views/private/ThankYouPageView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Thank You Page'
				}
			},
			{
				path: "/payment-history",
				name: "payment-history-view",
				component: () =>
					import("../views/private/PaymentHistoryView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Payment History Page'
				}
			},
			{
				path: "/settings/terms",
				name: "app-terms-view",
				component: () =>
					import("../views/private/AppTermsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Terms Page'
				}
			},
			{
				path: "/settings/privacy",
				name: "app-privacy-view",
				component: () =>
					import("../views/private/AppPrivacyView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Privacy Page'
				}
			},
			{
				path: "/settings/responsible-play",
				name: "app-responsible-play-view",
				component: () =>
					import("../views/private/AppResponsiblePlayView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Responsible Play Page'
				}
			},
			{
				path: "/settings/promotions-terms",
				name: "app-promotions-terms-view",
				component: () =>
					import("../views/private/AppPromotionsTermsView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Promotions Terms Page'
				}
			},
			{
				path: "/settings/loyalty-rules",
				name: "app-loyalty-rules-view",
				component: () =>
					import("../views/private/AppLoyaltyRulesView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Loyalty Rules Page'
				}
			},
			{
				// path: "*",
				path: "/:catchAll(.*)",
				name: "NotFound",
				component: () =>
					import("../views/404View.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: false,
					basic: true,
					title: 'Ooops'
				}
			},
			{
				// path: "*",
				path: "/404",
				name: "404-view",
				component: () =>
					import("../views/404View.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: false,
					basic: true,
					title: 'Ooops'
				}
			},
			{
				path: "/referral",
				name: "referral-view",
				component: () =>
					import("../views/private/ReferralView.vue"),
				meta: {
					requiresAuth: true,
					needsAppAccess: true,
					hasAccess: false,
					title: 'Referral link Page'
				}
			},
		]
	},
	{
		name: "landing",
		path: "/",
		component: LandingLayout,
		children: [
			{
				path: "/landing/welcome",
				name: "landing-view",
				component: () =>
					import("../views/public/LandingPageView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Welcome'
				}
			},
			{
				path: "/landing/welcome2",
				name: "landing-view2",
				component: () =>
					import("../views/public/LandingPageView2.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Welcome'
				}
			},
			{
				path: "/landing/wg",
				name: "landing-guest",
				component: () =>
					import("../views/public/LandingGuestView.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Welcome'
				}
			},
			{
				path: "/landing/wgd",
				name: "landing-guest-dash",
				component: () =>
					import("../views/public/LandingGuestDash.vue"),
				meta: {
					requiresAuth: false,
					needsAppAccess: false,
					title: 'Welcome'
				}
			},
		]
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: 'smooth',
			}
		}

		return { top: 0 }
	}
});

axios.interceptors.response.use(
	response => {
		if (response.status === 200 || response.status === 201) {
			return Promise.resolve(response);
			
		} else {
			return Promise.reject(response);
		}

	},
	error => {
		// console.log(error)
		if (error.response.status) {
			switch (error.response.status) {
				case 400:
					break;

				case 401:

					setTimeout(() => {
						localStorage.removeItem('user-token')
						window._cio.reset()
						VueCookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
						if (VueCookies.isKey('lnd_gst')) {
							VueCookies.remove('lnd_gst')
						}
						if (VueCookies.isKey('spn_cnt')) {
							VueCookies.remove('spn_cnt')
						}
						if(VueCookies.isKey('lnd_gst_dash')){
							VueCookies.remove('lnd_gst_dash')
						}
						if(!localStorage.getItem('user-token') && store.getters.authOn) {
							window.location.href = '/login'
						}
					}, 500)

					break;

				case 403:
					setTimeout(() => {

						localStorage.removeItem('user-token')
						window._cio.reset()
						VueCookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
						if (VueCookies.isKey('lnd_gst')) {
							VueCookies.remove('lnd_gst')
						}
						if (VueCookies.isKey('spn_cnt')) {
							VueCookies.remove('spn_cnt')
						}
						if(VueCookies.isKey('lnd_gst_dash')){
							VueCookies.remove('lnd_gst_dash')
						}
						if(!localStorage.getItem('user-token') && store.getters.authOn) {
							window.location.href = '/login'
						}
					}, 500)
					break;

				case 404:
					router.push({ path: '/404' })
					break;

				case 502:
					// store.dispatch('hideLoading')
					localStorage.removeItem('user-token')
					window._cio.reset()
					VueCookies.remove(process.env.VUE_APP_GUEST_USER_PREFIX)
					if (VueCookies.isKey('lnd_gst')) {
							VueCookies.remove('lnd_gst')
						}
						if (VueCookies.isKey('spn_cnt')) {
							VueCookies.remove('spn_cnt')
						}
						if(VueCookies.isKey('lnd_gst_dash')){
							VueCookies.remove('lnd_gst_dash')
						}
					if(!localStorage.getItem('user-token') && store.getters.authOn) {
						window.location.href = '/login'
					}
			}
			return Promise.reject(error.response);
		}
	}
);


router.beforeEach( async (to, from, next) => {
	document.title = to.meta.title ? to.meta.title + ' ' + '- Free Slots - Slotofun'  : "Free Slots - Slotofun"
	let needsAuth = to.meta.requiresAuth;
	let authOn =  store.getters.isAuthenticated;

	window._cio.page(to.meta.title, {
		path: to.path,
		url: window.location.origin + to.path
	})

	if (!needsAuth && authOn) {
		router.replace({
			path: "/dashboard",
			query: { redirect: router.currentRoute.fullPath }
		});

		return next({ path: '/dashboard' });
	}


	let intrv = null
	clearInterval(intrv)


	if (needsAuth) {
		if (!authOn) {
			window._cio.reset()
			intrv = null
			clearInterval(intrv)
			return next({ path: '/login' });
		}
	}

	if (!authOn) {
		intrv = null
		clearInterval(intrv)
	}

	if (from === START_LOCATION && authOn) {

		intrv = setInterval(() => {
			UserService.pingClaimableReward()
				.then((data) => {
					store.dispatch('setTimeRewards', data.data.time_elapsed)
					store.dispatch('setClaimableItemsCount', data.data.claimable_items)
				})
		}, 1000 * 60)

		UserService.getUserData()
			.then((response) => {
				store.dispatch('setUser', response.data.data)
				store.dispatch('setReferral', response.data.data.details.referral)
				store.dispatch('setTimeRewards', response.data.data.active_time_reward.last_ping_count)
				store.dispatch('setClaimableItemsCount', response.data.data.active_time_reward.claimable_items)
				store.dispatch('setSoundState', response.data.data.details.sound)
				//  && to.path !== `/game/${response.data.data.game}` need allowed game slug on the /me request
				if (!response.data.data.dashboard && to.meta.needsAppAccess && !VueCookies.isKey('lnd_gst_dash') && to.path !== '/dashboard' && to.path !== `/game/${response.data.data.details.highlight_first_game}`) {
					router.replace({
						path: "/dashboard",
						query: { redirect: router.currentRoute.fullPath }
					});
				}
		})

	} else {
		clearInterval(intrv)
	}

	if(!localStorage.getItem('user-token') && authOn) {
		store.dispatch('logoutRequest').then(() => {
			window._cio.reset()
			intrv = null
			clearInterval(intrv)
			window.location.href = '/'
		})
	} else {
		return next()
	}

	return next();
});

export default router;


