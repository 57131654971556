<template>
    <div>
        <router-view></router-view>>
    </div>
</template>

<script>

export default {
	name: 'landing-view',
	components: {},

	methods: {},

	updated () {},

	created(){},

	mounted() {}
}

</script>