import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/router";
import VueAxios from 'vue-axios';
import axios from 'axios';
import store from './store/index'
import mixins from './mixins/mixins'
import * as bootstrap from 'bootstrap';
import './assets/sass/app.scss'
import Echo from 'laravel-echo'
import VueCookies from 'vue-cookies';
import VueTippy from 'vue-tippy'
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueConfetti from 'vue-confetti'
import Loading from 'vue3-loading-screen'
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'
import VueGtag from 'vue-gtag'
import 'particles.js'
import './registerServiceWorker'


const app = createApp(App)

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});

app.use(store)
  .use(VueAxios, axios)
  .use(router)
  .use(bootstrap)
  .use(VueCookies)
  .use(VueTippy)
  .use(VueCountdown)
  .use(metaPlugin)
  .use(metaManager)
  .use(VueConfetti)
  .use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
    loaderOptions: {
      useRecaptchaNet: true,
      explicitRenderParameters: {
        // container: 'recaptchaContainer',
        // badge: 'inline',
        // size: 'invisible',
        autoHideBadge: true
      },
    },
  })
  .use(Loading, {
    slot: `	<div class="background-loading">
                            <div class="box">
                              <div class="b b1"></div>
                              <div class="b b2"></div>
                              <div class="b b3"></div>
                              <div class="b b4"></div>
                            </div>
                          </div>
                        `
  })
  .mount('#app')


if(process.env.VUE_APP_ENV === 'production') {
  app.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GTAG
    }
  })
}


app.mixin(mixins)

window.Pusher = require('pusher-js')
window.lax = require('lax.js')

axios.defaults.withCredentials = false;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.responseType = 'json'

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
const token = localStorage.getItem('user-token') || null

if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_HOST_URL,
    wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    authEndpoint: `${process.env.VUE_APP_API_ENDPOINT}/broadcasting/auth`,
    encrypted: false,
    forceTLS: false,
    //  wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws'/*, 'wss'*/],
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    auth: {
      headers: {
        authorization: 'Bearer ' + token,
      }
    }
  })
}


window.$ = window.jQuery = require('jquery');
